import * as React from "react";

const LogoSmall = () => {
  return (
    <svg width="80" height="86" viewBox="0 0 80 86" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_112_2)">
<path d="M57.55 51.4554V38.344H60.5037V40.572H60.6573C60.9303 39.8208 61.3829 39.2346 62.0146 38.8135C62.646 38.3869 63.4002 38.1731 64.2764 38.1731C65.1643 38.1731 65.9125 38.3895 66.5217 38.8222C67.1361 39.2488 67.5688 39.8321 67.819 40.572H67.9557C68.246 39.8434 68.7354 39.2631 69.4239 38.8304C70.1179 38.3925 70.9405 38.1731 71.8907 38.1731C73.0971 38.1731 74.0815 38.5547 74.8443 39.3172C75.6068 40.0796 75.988 41.1921 75.988 42.6546V51.4554H72.8894V43.1328C72.8894 42.3189 72.6731 41.7243 72.2408 41.3487C71.8081 40.9675 71.2789 40.7767 70.6531 40.7767C69.9076 40.7767 69.3243 41.0099 68.9033 41.4768C68.4879 41.9376 68.2798 42.5382 68.2798 43.2777V51.4554H65.2496V43.0047C65.2496 42.3275 65.0449 41.787 64.6351 41.3829C64.231 40.9788 63.7018 40.7767 63.0475 40.7767C62.6036 40.7767 62.1994 40.8905 61.8351 41.1181C61.4712 41.3401 61.1808 41.656 60.9645 42.0657C60.7482 42.4699 60.6404 42.942 60.6404 43.4828V51.4554H57.55Z" fill="white" className="svg-elem-1"></path>
<path d="M50.5078 45.941V38.344H53.5981V51.4554H50.6017V49.1249H50.4654C50.1694 49.8592 49.6826 50.4593 49.0055 50.9262C48.3339 51.3927 47.5061 51.6263 46.5217 51.6263C45.6624 51.6263 44.9026 51.4355 44.2423 51.0543C43.588 50.6674 43.0757 50.1067 42.7061 49.3728C42.3362 48.6329 42.151 47.7393 42.151 46.6922V38.344H45.2413V46.2141C45.2413 47.0453 45.4689 47.7052 45.9241 48.1946C46.3794 48.6839 46.9769 48.9288 47.7164 48.9288C48.172 48.9288 48.613 48.8177 49.0396 48.5957C49.4667 48.3737 49.8164 48.0439 50.0898 47.6056C50.3685 47.1617 50.5078 46.6069 50.5078 45.941Z" fill="white" className="svg-elem-2"></path>
<path d="M39.9831 33.9735V51.4554H36.8929V33.9735H39.9831Z" fill="white" className="svg-elem-3"></path>
<path d="M33.598 33.9735V51.4554H30.5079V33.9735H33.598Z" fill="white" className="svg-elem-4"></path>
<path d="M20.3842 51.7202C19.5533 51.7202 18.805 51.5722 18.1392 51.2762C17.479 50.9746 16.9555 50.5307 16.5685 49.9444C16.1872 49.3585 15.9966 48.6355 15.9966 47.7765C15.9966 47.0366 16.1332 46.4248 16.4063 45.941C16.6795 45.4573 17.0522 45.0704 17.5245 44.7801C17.9969 44.4901 18.529 44.2708 19.1208 44.1228C19.7183 43.9692 20.3358 43.8584 20.9731 43.79C21.7414 43.7104 22.3645 43.6391 22.8425 43.5767C23.3205 43.5084 23.6677 43.4058 23.8839 43.2695C24.1058 43.1271 24.2168 42.9078 24.2168 42.6122V42.5607C24.2168 41.9177 24.0262 41.4197 23.6449 41.067C23.2636 40.7144 22.7145 40.5378 21.9974 40.5378C21.2406 40.5378 20.6402 40.7027 20.1964 41.0329C19.7582 41.363 19.4622 41.7529 19.3086 42.2024L16.4234 41.7927C16.651 40.9961 17.0266 40.3301 17.5502 39.7953C18.0737 39.2545 18.7139 38.8503 19.4708 38.5829C20.2276 38.3099 21.0642 38.1731 21.9804 38.1731C22.6121 38.1731 23.2409 38.2471 23.8669 38.3951C24.4928 38.5431 25.0648 38.788 25.5826 39.1294C26.1004 39.4652 26.5159 39.923 26.8288 40.5037C27.1475 41.0839 27.3069 41.8096 27.3069 42.6802V51.4554H24.3363V49.6545H24.2339C24.0461 50.0184 23.7815 50.3598 23.44 50.6787C23.1043 50.9915 22.6803 51.2447 22.1682 51.4385C21.6617 51.6263 21.067 51.7202 20.3842 51.7202ZM21.1865 49.4494C21.8068 49.4494 22.3446 49.3269 22.7999 49.0824C23.2551 48.8319 23.6051 48.5018 23.8498 48.092C24.1002 47.6826 24.2254 47.2357 24.2254 46.7519V45.2071C24.1286 45.2868 23.9636 45.3607 23.7303 45.4291C23.5026 45.4971 23.2466 45.5568 22.962 45.6083C22.6775 45.6593 22.3958 45.7048 22.117 45.7446C21.8381 45.7848 21.5962 45.8186 21.3914 45.8471C20.9304 45.9099 20.5179 46.0124 20.1537 46.1544C19.7894 46.2967 19.5021 46.4958 19.2915 46.7519C19.081 47.0024 18.9757 47.3269 18.9757 47.7251C18.9757 48.2941 19.1834 48.7238 19.5988 49.0141C20.0142 49.3044 20.5435 49.4494 21.1865 49.4494Z" fill="white" className="svg-elem-5"></path>
<path d="M8.43368 51.7115C7.1248 51.7115 6.00089 51.4242 5.06192 50.8492C4.12863 50.2746 3.40877 49.4806 2.9023 48.4676C2.40152 47.449 2.15112 46.2768 2.15112 44.951C2.15112 43.6192 2.40721 42.4443 2.91937 41.4253C3.43154 40.4011 4.15424 39.6045 5.08754 39.0355C6.02651 38.4608 7.13618 38.1731 8.41659 38.1731C9.48074 38.1731 10.4226 38.3696 11.242 38.7624C12.0672 39.1493 12.7245 39.6984 13.2139 40.4098C13.7033 41.1155 13.9821 41.9407 14.0504 42.8853H11.0969C10.9774 42.2535 10.6929 41.7269 10.2433 41.3059C9.79943 40.8792 9.20476 40.6659 8.4593 40.6659C7.8276 40.6659 7.27278 40.8364 6.79473 41.1778C6.31672 41.5136 5.94399 41.9974 5.67654 42.6291C5.41475 43.2608 5.28386 44.0176 5.28386 44.8995C5.28386 45.793 5.41475 46.5615 5.67654 47.2045C5.93828 47.8419 6.30534 48.3339 6.77768 48.6813C7.25569 49.0227 7.81622 49.1932 8.4593 49.1932C8.91454 49.1932 9.32142 49.108 9.67995 48.9371C10.0441 48.7609 10.3486 48.5078 10.5933 48.1776C10.838 47.8475 11.0059 47.4464 11.0969 46.9739H14.0504C13.9764 47.9016 13.7033 48.7238 13.2309 49.4407C12.7586 50.1521 12.1156 50.7099 11.3018 51.114C10.488 51.5125 9.53197 51.7115 8.43368 51.7115Z" fill="white" className="svg-elem-6"></path>
<path d="M57.55 51.4554V38.344H60.5037V40.572H60.6573C60.9303 39.8208 61.3829 39.2346 62.0146 38.8135C62.646 38.3869 63.4002 38.1731 64.2764 38.1731C65.1643 38.1731 65.9125 38.3895 66.5217 38.8222C67.1361 39.2488 67.5688 39.8321 67.819 40.572H67.9557C68.246 39.8434 68.7354 39.2631 69.4239 38.8304C70.1179 38.3925 70.9405 38.1731 71.8907 38.1731C73.0971 38.1731 74.0815 38.5547 74.8443 39.3172C75.6068 40.0796 75.988 41.1921 75.988 42.6546V51.4554H72.8894V43.1328C72.8894 42.3189 72.6731 41.7243 72.2408 41.3487C71.8081 40.9675 71.2789 40.7767 70.6531 40.7767C69.9076 40.7767 69.3243 41.0099 68.9033 41.4768C68.4879 41.9376 68.2798 42.5382 68.2798 43.2777V51.4554H65.2496V43.0047C65.2496 42.3275 65.0449 41.787 64.6351 41.3829C64.231 40.9788 63.7018 40.7767 63.0475 40.7767C62.6036 40.7767 62.1994 40.8905 61.8351 41.1181C61.4712 41.3401 61.1808 41.656 60.9645 42.0657C60.7482 42.4699 60.6404 42.942 60.6404 43.4828V51.4554H57.55Z" stroke="white" strokeWidth="2" className="svg-elem-7"></path>
<path d="M50.5078 45.941V38.344H53.5981V51.4554H50.6017V49.1249H50.4654C50.1694 49.8592 49.6826 50.4593 49.0055 50.9262C48.3339 51.3927 47.5061 51.6263 46.5217 51.6263C45.6624 51.6263 44.9026 51.4355 44.2423 51.0543C43.588 50.6674 43.0757 50.1067 42.7061 49.3728C42.3362 48.6329 42.151 47.7393 42.151 46.6922V38.344H45.2413V46.2141C45.2413 47.0453 45.4689 47.7052 45.9241 48.1946C46.3794 48.6839 46.9769 48.9288 47.7164 48.9288C48.172 48.9288 48.613 48.8177 49.0396 48.5957C49.4667 48.3737 49.8164 48.0439 50.0898 47.6056C50.3685 47.1617 50.5078 46.6069 50.5078 45.941Z" stroke="white" strokeWidth="2" className="svg-elem-8"></path>
<path d="M39.9831 33.9735V51.4554H36.8929V33.9735H39.9831Z" stroke="white" strokeWidth="2" className="svg-elem-9"></path>
<path d="M33.598 33.9735V51.4554H30.5079V33.9735H33.598Z" stroke="white" strokeWidth="2" className="svg-elem-10"></path>
<path d="M20.3842 51.7202C19.5533 51.7202 18.805 51.5722 18.1392 51.2762C17.479 50.9746 16.9555 50.5307 16.5685 49.9444C16.1872 49.3585 15.9966 48.6355 15.9966 47.7765C15.9966 47.0366 16.1332 46.4248 16.4063 45.941C16.6795 45.4573 17.0522 45.0704 17.5245 44.7801C17.9969 44.4901 18.529 44.2708 19.1208 44.1228C19.7183 43.9692 20.3358 43.8584 20.9731 43.79C21.7414 43.7104 22.3645 43.6391 22.8425 43.5767C23.3205 43.5084 23.6677 43.4058 23.8839 43.2695C24.1058 43.1271 24.2168 42.9078 24.2168 42.6122V42.5607C24.2168 41.9177 24.0262 41.4197 23.6449 41.067C23.2636 40.7144 22.7145 40.5378 21.9974 40.5378C21.2406 40.5378 20.6402 40.7027 20.1964 41.0329C19.7582 41.363 19.4622 41.7529 19.3086 42.2024L16.4234 41.7927C16.651 40.9961 17.0266 40.3301 17.5502 39.7953C18.0737 39.2545 18.7139 38.8503 19.4708 38.5829C20.2276 38.3099 21.0642 38.1731 21.9804 38.1731C22.6121 38.1731 23.2409 38.2471 23.8669 38.3951C24.4928 38.5431 25.0648 38.788 25.5826 39.1294C26.1004 39.4652 26.5159 39.923 26.8288 40.5037C27.1475 41.0839 27.3069 41.8096 27.3069 42.6802V51.4554H24.3363V49.6545H24.2339C24.0461 50.0184 23.7815 50.3598 23.44 50.6787C23.1043 50.9915 22.6803 51.2447 22.1682 51.4385C21.6617 51.6263 21.067 51.7202 20.3842 51.7202ZM21.1865 49.4494C21.8068 49.4494 22.3446 49.3269 22.7999 49.0824C23.2551 48.8319 23.6051 48.5018 23.8498 48.092C24.1002 47.6826 24.2254 47.2357 24.2254 46.7519V45.2071C24.1286 45.2868 23.9636 45.3607 23.7303 45.4291C23.5026 45.4971 23.2466 45.5568 22.962 45.6083C22.6775 45.6593 22.3958 45.7048 22.117 45.7446C21.8381 45.7848 21.5962 45.8186 21.3914 45.8471C20.9304 45.9099 20.5179 46.0124 20.1537 46.1544C19.7894 46.2967 19.5021 46.4958 19.2915 46.7519C19.081 47.0024 18.9757 47.3269 18.9757 47.7251C18.9757 48.2941 19.1834 48.7238 19.5988 49.0141C20.0142 49.3044 20.5435 49.4494 21.1865 49.4494Z" stroke="white" strokeWidth="2" className="svg-elem-11"></path>
<path d="M8.43368 51.7115C7.1248 51.7115 6.00089 51.4242 5.06192 50.8492C4.12863 50.2746 3.40877 49.4806 2.9023 48.4676C2.40152 47.449 2.15112 46.2768 2.15112 44.951C2.15112 43.6192 2.40721 42.4443 2.91937 41.4253C3.43154 40.4011 4.15424 39.6045 5.08754 39.0355C6.02651 38.4608 7.13618 38.1731 8.41659 38.1731C9.48074 38.1731 10.4226 38.3696 11.242 38.7624C12.0672 39.1493 12.7245 39.6984 13.2139 40.4098C13.7033 41.1155 13.9821 41.9407 14.0504 42.8853H11.0969C10.9774 42.2535 10.6929 41.7269 10.2433 41.3059C9.79943 40.8792 9.20476 40.6659 8.4593 40.6659C7.8276 40.6659 7.27278 40.8364 6.79473 41.1778C6.31672 41.5136 5.94399 41.9974 5.67654 42.6291C5.41475 43.2608 5.28386 44.0176 5.28386 44.8995C5.28386 45.793 5.41475 46.5615 5.67654 47.2045C5.93828 47.8419 6.30534 48.3339 6.77768 48.6813C7.25569 49.0227 7.81622 49.1932 8.4593 49.1932C8.91454 49.1932 9.32142 49.108 9.67995 48.9371C10.0441 48.7609 10.3486 48.5078 10.5933 48.1776C10.838 47.8475 11.0059 47.4464 11.0969 46.9739H14.0504C13.9764 47.9016 13.7033 48.7238 13.2309 49.4407C12.7586 50.1521 12.1156 50.7099 11.3018 51.114C10.488 51.5125 9.53197 51.7115 8.43368 51.7115Z" stroke="white" strokeWidth="2" className="svg-elem-12"></path>
<path d="M80 0.37558H76.9953V30.0469H80V0.37558Z" fill="white" stroke="white" strokeWidth="2" className="svg-elem-13"></path>
<path d="M78.6855 0.18779H0.187805V3.19248H78.6855V0.18779Z" fill="white" stroke="white" strokeWidth="2" className="svg-elem-14"></path>
<path d="M3.1925 2.44131H0.187805V32.1127H3.1925V2.44131Z" fill="white" stroke="white" strokeWidth="2" className="svg-elem-15"></path>
<path d="M3.1925 55.3991H0.187805V85.0704H3.1925V55.3991Z" fill="white" stroke="white" strokeWidth="2" className="svg-elem-16"></path>
<path d="M80 82.2535H1.50232V85.2582H80V82.2535Z" fill="white" stroke="white" strokeWidth="2" className="svg-elem-17"></path>
<path d="M80 54.8357H76.9953V84.507H80V54.8357Z" fill="white" stroke="white" strokeWidth="2" className="svg-elem-18"></path>
</g>
<defs>
<clipPath id="clip0_112_2">
<rect width="80" height="85.2582" fill="white" className="svg-elem-19"></rect>
</clipPath>
</defs>
</svg>

  );
};

export default LogoSmall;
